var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('v-dialog', {
    attrs: {
      "value": true,
      "persistent": "",
      "max-width": "480px"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-calendar-plus")]), !_vm.created ? [_vm._v("Új foglalás")] : [_vm._v("Foglalás módosítása")]], 2), _c('v-card-text', {
    staticClass: "mt-12"
  }, [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('v-datetime-picker', {
    attrs: {
      "label": "Foglalás kezdete",
      "error-messages": _vm.errors.start_datetime,
      "filled": ""
    },
    model: {
      value: _vm.start_datetime,
      callback: function ($$v) {
        _vm.start_datetime = $$v;
      },
      expression: "start_datetime"
    }
  }), _c('v-datetime-picker', {
    attrs: {
      "label": "Foglalás vége",
      "error-messages": _vm.errors.end_datetime,
      "filled": ""
    },
    model: {
      value: _vm.end_datetime,
      callback: function ($$v) {
        _vm.end_datetime = $$v;
      },
      expression: "end_datetime"
    }
  }), _c('v-textarea', {
    attrs: {
      "filled": "",
      "label": "Megjegyzés",
      "rows": "2",
      "auto-grow": ""
    },
    model: {
      value: _vm.remark,
      callback: function ($$v) {
        _vm.remark = $$v;
      },
      expression: "remark"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Mégse")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "depressed": "",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Importálás ")])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }